import React, { useState, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';
import { CreateJoin } from './components/CreateJoin';
import { GameRoom } from './components/GameRoom';
import MainLayout from './components/MainLayout';
import { Player, GameState, SubmittedWord } from './Types';
import './index.css';
import './App.css';

const App: React.FC = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [waitingPlayers, setWaitingPlayers] = useState<Player[]>([]);
  const [word, setWord] = useState<string | null>(null);
  const [isImposter, setIsImposter] = useState<boolean>(false);
  const [creatorId, setCreatorId] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | null>(null);
  const [round, setRound] = useState<number>(0);
  const [roundWords, setRoundWords] = useState<{[key: string]: string}>({});
  const [isVoting, setIsVoting] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [submittedWords, setSubmittedWords] = useState<{[key: string]: SubmittedWord}>({});
  const [submissionTime, setSubmissionTime] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const newSocket = io();
    setSocket(newSocket);

    newSocket.on('playerJoined', (player: Player) => {
      setPlayers(prevPlayers => [...prevPlayers, player]);
      setMessage(`${player.name} has joined the game!`);
    });

    newSocket.on('playerWaiting', (player: Player) => {
      setWaitingPlayers(prevWaitingPlayers => [...prevWaitingPlayers, player]);
      setMessage(`${player.name} is waiting to join the game.`);
    });

    newSocket.on('gameStarted', (data: { imposterId: string; word: string; round: number; players: Player[]; waitingPlayers: Player[]; submissionTime: number }) => {
      setIsImposter(data.imposterId === newSocket.id);
      setWord(data.imposterId === newSocket.id ? null : data.word);
      setRound(data.round);
      setPlayers(data.players);
      setWaitingPlayers(data.waitingPlayers);
      setMessage(data.imposterId === newSocket.id ? "You are the imposter!" : `The word is: ${data.word}`);
      setGameOver(false);
      setSubmissionTime(data.submissionTime);
      setTimeLeft(data.submissionTime);
      setSubmittedWords({});
    });

    newSocket.on('wordSubmitted', (data: { playerId: string, word: string, playerName: string }) => {
      setSubmittedWords(prev => ({
        ...prev,
        [data.playerId]: { word: data.word, name: data.playerName }
      }));
    });

    newSocket.on('gameRestarted', (data: { imposterId: string; word: string; round: number; players: Player[]; waitingPlayers: Player[] }) => {
      setIsImposter(data.imposterId === newSocket.id);
      setWord(data.imposterId === newSocket.id ? null : data.word);
      setRound(data.round);
      setPlayers(data.players);
      setWaitingPlayers(data.waitingPlayers);
      setMessage(data.imposterId === newSocket.id ? "You are the imposter!" : `The word is: ${data.word}`);
      setGameOver(false);
      setIsWaiting(false);
    });

    newSocket.on('waitingPlayerLeft', (playerId: string) => {
      setWaitingPlayers(prevWaitingPlayers => {
        const leavingPlayer = prevWaitingPlayers.find(p => p.id === playerId);
        if (leavingPlayer) {
          setMessage(`${leavingPlayer.name} has left the waiting list.`);
        }
        return prevWaitingPlayers.filter(p => p.id !== playerId);
      });
    });

    newSocket.on('playerLeft', (playerId: string) => {
      setPlayers(prevPlayers => {
        const leavingPlayer = prevPlayers.find(p => p.id === playerId);
        if (leavingPlayer) {
          setMessage(`${leavingPlayer.name} has left the game.`);
        }
        return prevPlayers.filter(p => p.id !== playerId);
      });
    });

    newSocket.on('roundComplete', (words: {[key: string]: string}) => {
      setRoundWords(words);
      setIsVoting(true);
      setSubmittedWords({});
    });

    newSocket.on('playerEliminated', (playerId: string) => {
      setPlayers(prevPlayers => prevPlayers.map(p =>
          p.id === playerId ? { ...p, isEliminated: true } : p
      ));
      const eliminatedPlayer = players.find(p => p.id === playerId);
      if (eliminatedPlayer) {
        setMessage(`${eliminatedPlayer.name} has been eliminated.`);
      }
      setIsVoting(false);
    });

    newSocket.on('newRound', (roundNumber: number) => {
      setRound(roundNumber);
      setRoundWords({});
      setIsVoting(false);
    });

    newSocket.on('gameOver', (data: { imposter: string; word: string; winner: 'crew' | 'imposter'; players: Player[] }) => {
      setMessage(`Game Over! The ${data.winner} wins. The word was: ${data.word}`);
      setIsVoting(false);
      setRound(0);
      setWord(null);
      setIsImposter(false);
      setGameOver(true);
      setRoundWords({});
      setPlayers(data.players);
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (submissionTime > 0 && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [submissionTime, timeLeft]);

  const createRoom = (playerName: string, isPublic: boolean, chosenLanguage: string) => {
    if (socket) {
      const socketId = socket.id ?? 'unknown';
      socket.emit('createRoom', playerName, isPublic, chosenLanguage, (newRoomId: string) => {
        setRoomId(newRoomId);
        setPlayers([{ id: socketId, name: playerName, isCreator: true, isEliminated: false, score: 0 }]);
        setCreatorId(socketId);
        setLanguage(chosenLanguage);
        setMessage(`You've created room ${newRoomId}`);
      });
    }
  };

  const joinRoom = (joinRoomId: string, playerName: string) => {
    if (socket && socket.id) {
      socket.emit('joinRoom', joinRoomId, playerName, (success: boolean, activePlayers: Player[], waitingPlayersList: Player[], roomCreatorId: string, roomLanguage: string, isPlayerWaiting: boolean, errorMessage?: string) => {
        if (success) {
          setRoomId(joinRoomId);
          setPlayers(activePlayers);
          setWaitingPlayers(waitingPlayersList);
          setCreatorId(roomCreatorId);
          setLanguage(roomLanguage);
          setIsWaiting(isPlayerWaiting);
          setMessage(isPlayerWaiting ? `You've joined the waiting list for room ${joinRoomId}` : `You've joined room ${joinRoomId}`);
        } else {
          setMessage(errorMessage || "Failed to join room. Please check the room ID.");
        }
      });
    }
  };

  const startGame = () => {
    if (socket && roomId) {
      socket.emit('startGame', roomId);
    }
  };

  const restartGame = () => {
    if (socket && roomId) {
      socket.emit('restartGame', roomId);
    }
  };

  const submitWord = (word: string) => {
    if (socket && roomId) {
      socket.emit('submitWord', roomId, word);
    }
  };

  const submitVote = (votedPlayerId: string) => {
    if (socket && roomId) {
      socket.emit('submitVote', roomId, votedPlayerId);
    }
  };

  return (
      <MainLayout>
        {message && <div className="mb-4 p-4 bg-blue-100 text-blue-700 rounded">{message}</div>}
        {!roomId ? (
            <CreateJoin createRoom={createRoom} joinRoom={joinRoom} />
        ) : (
            socket && roomId && (
                <GameRoom
                    roomId={roomId}
                    players={players}
                    waitingPlayers={waitingPlayers}
                    word={word}
                    isImposter={isImposter}
                    startGame={startGame}
                    restartGame={restartGame}
                    isCreator={socket.id === creatorId}
                    round={round}
                    submitWord={submitWord}
                    roundWords={roundWords}
                    isVoting={isVoting}
                    submitVote={submitVote}
                    language={language}
                    gameOver={gameOver}
                    socket={socket}
                    isWaiting={isWaiting}
                    submittedWords={submittedWords}
                    timeLeft={timeLeft}
                />
            )
        )}
      </MainLayout>
  );
};

export default App;