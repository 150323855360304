import React, { useState } from 'react';
import { Socket } from 'socket.io-client';
import { VoiceChat } from './VoiceChat';
import { GameLobby } from './GameLobby';
import {GameRoomProps} from '../Types';

export const GameRoom: React.FC<GameRoomProps> = ({
                                                      roomId,
                                                      players,
                                                      waitingPlayers,
                                                      word,
                                                      isImposter,
                                                      startGame,
                                                      restartGame,
                                                      isCreator,
                                                      round,
                                                      submitWord,
                                                      roundWords,
                                                      isVoting,
                                                      submitVote,
                                                      language,
                                                      gameOver,
                                                      socket,
                                                      isWaiting,
                                                      submittedWords,
                                                      timeLeft,
                                                  }) => {
    const [currentWord, setCurrentWord] = useState('');
    const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);

    const handleWordSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (currentWord.trim()) {
            submitWord(currentWord.trim());
            setCurrentWord('');
        }
    };

    const handleVoteSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedPlayer) {
            submitVote(selectedPlayer);
            setSelectedPlayer(null);
        }
    };

    const currentPlayer = players.find(p => p.id === socket.id);
    const isEliminated = currentPlayer?.isEliminated;

    if (round === 0 || gameOver) {
        return (
            <GameLobby
                roomId={roomId}
                players={players}
                waitingPlayers={waitingPlayers}
                isCreator={isCreator}
                language={language}
                startGame={gameOver ? restartGame : startGame}
            />
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            <header className="bg-teal-600 shadow-lg text-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <h2 className="text-2xl font-bold">Game Room</h2>
                </div>
            </header>
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="bg-white rounded-xl shadow-md p-6">
                    <div className="grid md:grid-cols-2 gap-6">
                        <div>
                            <p className="mb-2">Room ID: <span className="font-semibold">{roomId}</span></p>
                            <p className="mb-2">Language: <span className="font-semibold">{language}</span></p>
                            <p className="mb-2">Round: <span className="font-semibold">{round}</span></p>
                            <p className="mb-4">Time left: <span className="font-semibold">{timeLeft} seconds</span></p>
                            <VoiceChat socket={socket} roomId={roomId} />
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-2 text-teal-700">Players:</h3>
                            <ul className="list-disc pl-5">
                                {players.map((player) => (
                                    <li key={player.id} className={`${player.isEliminated ? 'line-through text-gray-500' : ''}`}>
                                        {player.name} {player.isCreator ? '(Creator)' : ''} - Score: {player.score}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {isWaiting ? (
                        <div className="mt-6 p-4 bg-yellow-100 text-yellow-700 rounded">
                            <p>You are in the waiting list. The game will start for you in the next round.</p>
                            <p>Please wait for the current game to finish.</p>
                        </div>
                    ) : (
                        <div className="mt-6">
                            {isImposter ? (
                                <div className="mb-4">
                                    <p className="text-red-600 font-bold">You are the imposter! Try to guess the word.</p>
                                    <h4 className="text-lg font-semibold mt-2">Submitted words:</h4>
                                    <ul className="list-disc pl-5">
                                        {Object.entries(submittedWords).map(([playerId, { word, name }]) => (
                                            <li key={playerId}>
                                                {name}: {word}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <p className="mb-4">The word is: <strong className="text-teal-700">{word}</strong></p>
                            )}
                            {!isVoting && !isEliminated && (
                                <form onSubmit={handleWordSubmit} className="mb-4">
                                    <input
                                        type="text"
                                        value={currentWord}
                                        onChange={(e) => setCurrentWord(e.target.value)}
                                        placeholder="Enter a related word"
                                        className="w-full p-2 mb-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
                                    />
                                    <button type="submit" className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
                                        Submit Word
                                    </button>
                                </form>
                            )}
                            {Object.keys(roundWords).length > 0 && (
                                <div className="mb-4">
                                    <h4 className="text-lg font-semibold text-teal-700">Words submitted this round:</h4>
                                    <ul className="list-disc pl-5">
                                        {Object.entries(roundWords).map(([playerId, word]) => (
                                            <li key={playerId}>
                                                {players.find(p => p.id === playerId)?.name}: {word}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {isVoting && !isEliminated && (
                                <form onSubmit={handleVoteSubmit} className="mb-4">
                                    <h4 className="text-lg font-semibold mb-2 text-teal-700">Vote for the Imposter</h4>
                                    <select
                                        value={selectedPlayer || ''}
                                        onChange={(e) => setSelectedPlayer(e.target.value)}
                                        className="w-full p-2 mb-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
                                    >
                                        <option value="">Select a player</option>
                                        {players.filter(p => !p.isEliminated).map((player) => (
                                            <option key={player.id} value={player.id}>
                                                {player.name}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="submit" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
                                        Submit Vote
                                    </button>
                                </form>
                            )}
                            {isEliminated && (
                                <p className="text-red-600">You have been eliminated. You can still watch the game, but you cannot submit words or vote.</p>
                            )}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};